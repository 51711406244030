import { FC } from "react"

import { Icon } from "../icon"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./Rating.module.scss"

const bem = create(styles, "Rating")

export type RatingClassNames = {
  container?: string
  icon?: string
}

export type RatingProps = {
  total: 1 | 2 | 3 | 4 | 5
  value: number
  classNames?: RatingClassNames
}

export const Rating: FC<RatingProps> = ({ total, value, classNames }) => {
  const { messages, formatMessage } = useTranslation()
  const translations = messages.components.common.rating

  // Calculate the percentage of stars that should not be filled
  const overlayWidth = 100 - Math.round((value / total) * 100)

  return (
    <span
      className={bem(undefined, undefined, classNames?.container)}
      aria-label={formatMessage(translations.label, { value, total })}
      role="img"
    >
      {Array.from(Array(total).keys()).map((_, i) => (
        <Icon
          key={i}
          name="star"
          className={bem("icon", undefined, classNames?.icon)}
          aria-hidden="true"
        />
      ))}
      {overlayWidth > 0 && (
        <span
          className={bem("overlay")}
          style={{ width: `${overlayWidth}%` }}
          aria-hidden="true"
        />
      )}
    </span>
  )
}
