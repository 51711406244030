import React, { FC } from "react"

import { CopyText, CopyTextProps } from "src/components/cck/elements/copyText"
import { Rating } from "src/components/common/rating"
import { create } from "src/helpers/bem"

import styles from "./QuoteTile.module.scss"

const bem = create(styles, "QuoteTile")

export type QuoteTileProps = {
  name?: string
  rating?: number
  quote?: CopyTextProps
}

/**
 * Tile that displays ratings from users
 *
 * It is not dynamic and need to be built in Contentful
 */
export const QuoteTile: FC<QuoteTileProps> = ({ name, rating, quote }) => {
  if (!name || !rating || !quote) return null

  return (
    <div className={bem()}>
      <div className={bem("header")}>
        <div className={bem("name")}>{name}</div>
        <Rating value={rating} total={5} />
      </div>

      <CopyText {...quote} options={{ isNote: true }} />
    </div>
  )
}
