import { useTranslation } from "src/hooks/translation"
import { formatters } from "src/translations/formatters"

type FormattedPriceProps = {
  price?: number
  exact?: boolean
  noDecimal?: boolean
  isSpaceBeforeCurrencySymbolNeeded?: boolean
}
type UseFormattedPrice = (p: FormattedPriceProps) => string

export const useFormattedPrice: UseFormattedPrice = ({
  price = 0,
  noDecimal = false,
  exact = false,
  isSpaceBeforeCurrencySymbolNeeded = true,
}) => {
  const { formatMessage, messages } = useTranslation()
  const currencyFormatter =
    formatters[noDecimal ? "currencyNoDecimals" : "currency"]

  const noSpace = ""

  const formatPrice = ({
    exact: isExactValue = exact,
    price: priceToCalculate = price,
  }: FormattedPriceProps) =>
    isExactValue
      ? currencyFormatter
          .format(priceToCalculate)
          .replace(/\s/g, (match) =>
            isSpaceBeforeCurrencySymbolNeeded ? match : noSpace,
          )
      : formatMessage(messages.pages.services.flow.services.price, {
          price: currencyFormatter
            .format(priceToCalculate)
            .replace(/\s/g, (match) =>
              isSpaceBeforeCurrencySymbolNeeded ? match : noSpace,
            ),
        })

  const formattedPrice = formatPrice({ price, exact })

  return formattedPrice ?? ""
}
