import React, { FC } from "react"

import { ContentfulButton, ContentfulButtonProps } from "../contentfulButton"
import { CopyText, CopyTextProps } from "src/components/cck/elements/copyText"
import { Headline, HeadlineProps } from "src/components/cck/elements/headline"
import {
  ContentfulImage,
  ContentfulImageProps,
} from "src/components/cck/elements/image"
import { create } from "src/helpers/bem"
import { ImageSourceSizes } from "src/helpers/contentful/createContentfulImageSources"
import { getContentfulAssetUrl } from "src/helpers/contentful/getContentfulAssetUrl"
import { useFormattedPrice } from "src/helpers/useFormattedPrice"

import styles from "./SalesTeaser.module.scss"

const bem = create(styles, "SalesTeaser")

type SalesTeaserSize = "sm" | "md" | "lg"

export const getImageSizes = (size: SalesTeaserSize): ImageSourceSizes => {
  const ratios = {
    sm: 3,
    md: 4,
    lg: 2,
  }
  const ratio = ratios[size]
  return [
    { maxScreenSize: 320, width: 320, scales: [1, 1.5] },
    { maxScreenSize: 480, width: 480, scales: [1, 1.5] },
    { maxScreenSize: 640, width: 640, scales: [1, 1.5] },
    { maxScreenSize: 768, width: 768, scales: [1, 1.5] },
    { maxScreenSize: 1080, width: 1080 / ratio, scales: [1, 1.5] },
    { maxScreenSize: 1278, width: 1278 / ratio, scales: [1, 2] },
    { maxScreenSize: 1440, width: 1440 / ratio, scales: [1, 1.5] },
    { maxScreenSize: 1920, width: 1920 / ratio, scales: [1, 1.5] },
  ]
}

const gradientForLargeSize =
  "linear-gradient(63.9deg, rgba(0, 0, 0, 0.5) 48.4%, rgba(0, 0, 0, 0) 90.66%)"

const gradientForSmallSize =
  "linear-gradient(358.28deg, rgba(0, 0, 0, 0.5) 29.63%, rgba(0, 0, 0, 0) 66.1%)"

export interface SalesTeaserProps {
  /** A Image item. Choose this or a background image */
  productImage?: ContentfulImageProps
  /** A Image item. Choose this or a product image */
  backgroundImage?: ContentfulImageProps
  /** Does not work with background image */
  backgroundColor?: "yellow" | "white"
  /** The small tag at the top of the tile (e.g. Aktion) */
  tileTag?: string
  /** A Headline item */
  headline: HeadlineProps
  /** A Copy Text item */
  copyText: CopyTextProps
  /** Plain text located at the bottom right, usually an asterisk or extra info */
  supplementaryText?: string
  /** A Button item */
  buttonData: ContentfulButtonProps
  theme?: "yellow" | "blue"
  size?: SalesTeaserSize
  /** Choose "white" only when using background image, and also test to see if there is enough contrast */
  textColor?: "black" | "white"
  disruptorPrice?: number
  /** If `true`, then we will not show the `ab` word */
  isDisruptorPriceExact?: boolean
  /** The text inside the Disruptor circle */
  disruptorPriceConditions?: string
}

/**
 * Sales teasers are simple teaser modules, which primarily serve to introduce promotions and services.
 *
 *  They are much more present than editorial teasers and can also be used for the very prominent presentation of special topic pages.
 */
export const SalesTeaser: FC<SalesTeaserProps> = ({
  productImage,
  backgroundImage,
  backgroundColor = "white",
  tileTag,
  headline,
  copyText,
  supplementaryText,
  buttonData,
  theme = "blue",
  size = "lg",
  textColor = "black",
  disruptorPrice,
  isDisruptorPriceExact = false,
  disruptorPriceConditions,
}) => {
  const backgroundImageUrl = getContentfulAssetUrl(
    backgroundImage?.url,
    "images",
  )
  const hasProductImage = !!productImage?.url

  const sizeModifiers = { [size]: true }
  const themeModifiers = { [theme]: true }
  const textColorModifiers = { [textColor]: true }
  const backgroundColorModifiers = { [backgroundColor]: true }

  const formattedPrice = useFormattedPrice({
    price: disruptorPrice,
    exact: isDisruptorPriceExact,
    noDecimal: true,
    isSpaceBeforeCurrencySymbolNeeded: false,
  })

  const message = formattedPrice.split(" ")[0]
  const price = formattedPrice.split(" ")[1]

  return (
    <div className={bem("container", sizeModifiers)}>
      <div
        style={
          !!backgroundImageUrl
            ? {
                backgroundImage: `${
                  size === "sm" ? gradientForSmallSize : gradientForLargeSize
                }, url(${backgroundImageUrl})`,
              }
            : undefined
        }
        className={bem(undefined, {
          ...sizeModifiers,
          ...backgroundColorModifiers,
        })}
      >
        {/* Tile tag */}
        {tileTag && (
          <div
            className={bem("tile-tag", { ...themeModifiers, ...sizeModifiers })}
          >
            {tileTag}
          </div>
        )}

        {/* Supplementary text */}
        {size !== "sm" && supplementaryText && (
          <div className={bem("supplementary-text", sizeModifiers)}>
            {supplementaryText}
          </div>
        )}

        {/* Left component */}
        <div
          className={bem("left", {
            ...sizeModifiers,
            "without-image-sm": !hasProductImage && size === "sm",
            "without-image": !hasProductImage && size !== "sm",
          })}
        >
          {hasProductImage && (
            <div className={bem("product-image", sizeModifiers)}>
              <ContentfulImage
                {...productImage}
                imageSizes={getImageSizes(size)}
              />
            </div>
          )}
        </div>

        {/* Right component */}
        <div className={bem("right", sizeModifiers)}>
          {/* Content */}
          <div className={bem("content", sizeModifiers)}>
            <div className={bem("headline", textColorModifiers)}>
              <Headline {...headline} />
            </div>

            <div className={bem("copy-text", textColorModifiers)}>
              <CopyText {...copyText} />
            </div>
          </div>

          {/* Button */}
          <div className={bem("button", sizeModifiers)}>
            {size === "sm" && supplementaryText && (
              <div
                className={bem("supplementary-text", {
                  ...sizeModifiers,
                  "with-button": true,
                })}
              >
                {supplementaryText}
              </div>
            )}
            <ContentfulButton {...buttonData} />
          </div>

          {/* Disruptor */}
          {disruptorPrice && (
            <div
              className={bem("disruptor", {
                ...themeModifiers,
                ...sizeModifiers,
              })}
            >
              <div className={bem("price-container")}>
                <span className={bem("message")}>{message}</span>
                <span className={bem("price")}>{price}</span>
              </div>
              <div className={bem("condition")}>{disruptorPriceConditions}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
