import React, { FC, PropsWithChildren } from "react"

import { create } from "src/helpers/bem"

import styles from "./IntroText.module.scss"

const bem = create(styles, "IntroText")

export type IntroTextProps = PropsWithChildren<{
  align?: "left" | "right" | "center"
}>

/**
 * Serves to explain the content in more detail and is thus a supplement to headlines behind which is should mostly be used.
 *
 * It's an embedded entry of Rich text.
 */
export const IntroText: FC<IntroTextProps> = ({ align = "left", children }) => {
  return <p className={bem(undefined, { [align]: true })}>{children}</p>
}
