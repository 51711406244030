import React, { FC } from "react"

import { CopyText, CopyTextProps } from "src/components/cck/elements/copyText"
import {
  ContentfulImage,
  ContentfulImageProps,
} from "src/components/cck/elements/image"
import { create } from "src/helpers/bem"
import { ImageSourceSizes } from "src/helpers/contentful/createContentfulImageSources"

import styles from "./InstructionalStep.module.scss"

const bem = create(styles, "InstructionalStep")

export const imageSizes: ImageSourceSizes = [
  { maxScreenSize: 1920, width: 282, height: 310, scales: [1] },
]

export type InstructionalStepProps = {
  /** A Image item */
  image: ContentfulImageProps
  /** A Copy Text item */
  copyText: CopyTextProps
  tileNumber?: string
}

/**
 * Instructional Step is a tiling module, which is used to present individual action steps.
 *
 * It is regularly used in the Slider Module and is therefore only available in one variant
 */
export const InstructionalStep: FC<InstructionalStepProps> = ({
  image,
  copyText,
  tileNumber,
}) => {
  return (
    <div className={bem()}>
      <div className={bem("image-container")}>
        {!!image?.url && (
          <div className={bem("image")}>
            <ContentfulImage {...image} imageSizes={imageSizes} />
          </div>
        )}

        {!!tileNumber && <div className={bem("tile-number")}>{tileNumber}</div>}
      </div>

      <div>
        <CopyText {...copyText} />
      </div>
    </div>
  )
}
